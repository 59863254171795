import FlowGalleryInstance from './flow-gallery-instance';
import FlowGalleryOptions from './flow-gallery-options';
import FlowGallerySettings from './flow-gallery-settings';
import './flow-gallery.scss';

export class FlowGallery {
    private readonly settings: FlowGallerySettings;
    private readonly galleryInstances: FlowGalleryInstance[] = [];

    constructor(userOptions?: FlowGalleryOptions) {
        this.fullUpdate = this.fullUpdate.bind(this);
        this.update = this.update.bind(this);
        this.settings = new FlowGallerySettings();
        this.settings.applyOptions(userOptions);

        const galleries = document.querySelectorAll<HTMLElement>(this.settings.selector);

        galleries.forEach(gallery => {
            this.galleryInstances.push(new FlowGalleryInstance(this.settings, gallery));
        });

        window.addEventListener('resize', this.fullUpdate);
        this.update();
    }

    public fullUpdate(): void {
        this.galleryInstances.forEach(gallery => {
            gallery.fullUpdate();
        });
    }

    public update(): void {
        this.galleryInstances.forEach(gallery => {
            gallery.update();
        });
    }
}
